import React from 'react';
import { withTranslation } from 'react-i18next';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import { Button } from 'components/forms';
import { PaymentTemplate, PaymentDetails, PaymentError } from 'pages/Payments/components';
import { Amount, Status } from '.';

import { typography } from 'theme/mixins';

const Wrapper = styled.div`
  ${PaymentError} {
    margin: 16px 0;
  }
`;

const Header = styled.header`
  padding: 24px 0 8px;
  ${typography('h2')}
`;

const Body = styled(PaymentTemplate.Body)``;

const Footer = styled(PaymentTemplate.Footer)`
  margin-top: 16px;
`;

const PaymentControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 48px 0 24px;

  ${Amount} {
    margin-right: 20px;

    @media (min-width: 768px) {
      margin-right: 36px;
    }
  }
`;

@inject('paymentStore')
@withRouter
@observer
class Details extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    history: ReactRouterPropTypes.history,
    paymentStore: PropTypes.object.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @computed get isError() {
    const { paymentStore } = this.props;
    return paymentStore.isError;
  }

  @computed get isPending() {
    const { paymentStore } = this.props;
    return paymentStore.isPending;
  }

  @computed get isFetched() {
    const { paymentStore } = this.props;
    return paymentStore.isFetched;
  }

  @computed get payment() {
    const { paymentStore } = this.props;
    return paymentStore.data?.toJSON() || {};
  }

  handlePaymentRegister = () => {
    const { paymentStore } = this.props;
    const { token } = this.payment;

    paymentStore
      .register(token)
      .then((store) => {
        const { form_url } = store.data.toJSON();
        window.location.replace(form_url);
      });
  }

  renderPayButton = (status) => {
    const { t } = this.props;
    const { form_url } = this.payment;

    if (status === 0) {
      return (
        <Button
          rounded
          variant="success"
          type="button"
          target="_blank"
          href={form_url}
          loading={this.isPending}
          disabled={this.isPending}
        >
          {t('UI.Pay')}
        </Button>
      );
    }

    if (status === -2) {
      return (
        <Button
          rounded
          variant="success"
          type="button"
          loading={this.isPending}
          disabled={this.isPending}
          onClick={this.handlePaymentRegister}
        >
          {t('UI.Pay')}
        </Button>
      );
    }
    
    return null;
  }

  render() {
    const { t, ...rest } = this.props;
    const { amount, status } = this.payment;

    return (
      <Wrapper {...rest}>
        {this.isError
          ? (
            <PaymentError>
              <PaymentError.Title>
                {t('Booking.Errors.Fetching.Title')}
              </PaymentError.Title>

              <PaymentError.Description>
                {t('Booking.Errors.Fetching.Description')}
              </PaymentError.Description>
            </PaymentError>
          )
          : (
            <>
              <Header>
                {t('Booking.Details.Data.Title')}
              </Header>

              <Body>
                <PaymentDetails />
              </Body>

              {this.isFetched && (
                <Footer>
                  {(status === 0 || status === -2)
                    ? (
                      <PaymentControl>
                        <Amount value={amount} />

                        {this.renderPayButton(status)}
                      </PaymentControl>
                    )
                    : <Status status={status} />
                  }
                  
                </Footer>
              )}
            </>
          )
        }
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Details))``;
